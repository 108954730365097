import React from 'react';

function Products() {

  return (
    <>
    <div className="wrapper">
      <div className="container blog">
        <div data-aos="fade-left"
             data-aos-anchor-placement="top-center">
        </div>
        <div className="row article">
          <div className="col-lg-12" data-aos="fade-right"
               data-aos-anchor-placement="top-center">
            <div className="col-lg-12 sitewrapper" id="first">
              <div className="col-lg-6 bg-white">
                <h1 className="text-center">Związek Polaków w
                  Niemczech</h1>
                <p>&nbsp;</p>
                <p>Związek Polaków w Niemczech powstał 27 sierpnia 1922 roku i istniał do 1940r.&nbsp; Jego siedzibą do
                  września 1939 roku była nieruchomość w <a
                    href="https://pl.wikipedia.org/wiki/Berlin">Berlinie</a> znajdująca się pod adresem Potsdamerstraße
                  118b, Berlin. &nbsp;Do jego zadań należało "zdobycie dla ludności polskiej w Niemczech praw
                  mniejszości narodowej i obrona jej interesów we wszystkich dziedzinach".&nbsp;Związek prowadził
                  działalność wydawniczą, spółdzielczą i oświatową. Związek Polaków w Niemczech podporządkował sobie
                  większość polskich stowarzyszeń i pracował na rzecz utrzymania świadomości narodowej polskiej
                  ludności.</p>
                <p>&nbsp;</p>
                <p>Związek Polaków w Niemczech (ZPwN) organizacyjnie podzielony był na dzielnice, które obejmowały
                  tereny skupiające znaczną liczbę Polaków. Pierwotnie istniały cztery dzielnice:</p>
                <ul>
                  <li>I Opole – Śląsk Opolski</li>
                  <li>II Berlin – objęła Brandenburgię z Berlinem, Saksonię, Hamburg, Dolny Śląsk, Pomorze i Marchię
                    Graniczną
                  </li>
                  <li>III Bochum –&nbsp; obejmowała Westfalię, Nadrenię, Badenię i Palatynat (największa – prawie połowa
                    członków ZPwN)
                  </li>
                  <li>IV Olsztyn – Prusy Wschodnie</li>
                </ul>
                <p>Jednak już w październiku 1923 roku wyodrębniono dzielnicę V,&nbsp; obejmująca pogranicze
                  złotowskie.</p>
                <p>Organizacja w 1924 roku, jak wynika z zachowanych archiwaliów, liczyła ok 32 tys. członków.</p>
                <p>ZPwN prowadził działalność kulturalną, gospodarczą i polityczną, miał swoich reprezentantów w Sejmie
                  Pruskim, a także w innych organach samorządowych Republiki Weimarskiej. Jej główne dwa cele to ochrona
                  praw polskiej mniejszości narodowej w Niemczech oraz dbałość o polskie dziedzictwo kulturowe, jakie
                  znajdowało się w granicach Niemiec. (min. uratowano przed wyburzeniem wieżę piastowską w Opolu
                  –&nbsp; ostatni relikt Opolskiego Zamku Piastowskiego.</p>
                <p>Z inicjatywy ZPwN powstał Centralny Bank Spółdzielczości Polskiej, tzw. Bank Słowiański z siedzibą w
                  Berlinie, odegrał on ważną rolę w finansowaniu Związku i działań Polaków w Niemczech (zlikwidowano go
                  w 1939 roku). Polacy mieli wówczas swoje spółki rolno-handlowe, spółdzielnie i zjednoczenia zawodowe,
                  drukarnie, gazety, szkoły, bursy, instytucje opieki socjalnej, stowarzyszenia kulturalne, zespoły
                  artystyczne oraz kluby sportowe.</p>
                <p>Członkowie Związku katalogowali akty bezprawia dokonywane w Niemczech na członkach mniejszości
                  polskiej, zarówno w czasach Republiki Weimarskiej jak i nazistowskiej III Rzeszy Niemieckiej.
                  Udzielały pomocy poszkodowanym i ich rodzinom oraz reprezentowały ich interesy przed wymiarem
                  sprawiedliwości.</p>
                <p>6 marca 1938 r., wobec trwającej nagonki na Polaków mieszkających w Niemczech, zwołano I Kongres
                  Związku Polaków w Niemczech, w którym wzięło udział ok 5 tys. delegatów. Odbył się on w Teatrze
                  Ludowym&nbsp; w Berlinie.</p>
                <p>&nbsp;</p>
                <p>&nbsp;W&nbsp; trakcie trwania Kongresu uchwalono <em>Pięć prawd Polaka</em>:</p>
                <ol>
                  <li>Jesteśmy Polakami</li>
                  <li>Wiara Ojców naszych jest wiarą naszych dzieci</li>
                  <li>Polak Polakowi bratem</li>
                  <li>Co dzień Polak Narodowi służy</li>
                  <li>Polska Matką naszą, nie wolno mówić o Matce źle</li>
                </ol>
                <p>Stanowiły one duchową podstawę działalności związku.</p>
                <p>Przebieg Kongresu miał zostać zarejestrowany w ramach umowy Polskiego Radia z niemiecką rozgłośnią
                  Deustchlandsender. Członkowie Związku w obawie przed inwigilacją Gestapo potajemnie nagrali wiec na
                  taśmie magnetofonowej. Nagrania posłużyły do przygotowania pamiątkowych płyt z zapisem Kongresu oraz
                  do stworzenia reportażu z wydarzenia, wyemitowanego na antenie Polskiego Radia 12 marca 1938 roku.</p>
                <p>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/BG1ga2j74ok"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe></p>
                <p>&nbsp;</p>
                <p><strong>Symbole Związku</strong></p>
                <p><strong>Rodło</strong> – po dojściu Hitlera do władzy&nbsp;działacze organizacji&nbsp; postanowili
                  utworzyć&nbsp; znak, który umożliwi obejście wszystkich zakazów (np. zakaz używania przez Polaków
                  symboli nawiązujących do Orła Białego), a jednocześnie będzie podkreślał narodowy charakter
                  organizacji. W ten sposób powstało Rodło, przedstawiające bieg Wisły z zaznaczonym Krakowem. Znak
                  Rodła został zaprojektowany przez <a href="https://pl.wikipedia.org/wiki/Janina_K%C5%82opocka">Janinę
                    Kłopocką</a>&nbsp; ( tu daj link do Wikipedii:&nbsp; <a
                    href="https://pl.wikipedia.org/wiki/Janina_K%C5%82opocka">https://pl.wikipedia.org/wiki/Janina_K%C5%82opocka</a>)&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                </p>
                <p>Początkowo władze niemieckie przychylnie przyjęły ten symbol, sądząc, iż nawiązuje on do „połowy
                  swastyki”. W ten sprytny sposób Polacy w Niemczech uniknęli przyjęcia symboliki nazistowskiej. „W
                  rzeczywistości bowiem pozornie podobny znak oznaczał łączność Polaków z Niemiec z macierzą i był
                  przeciwstawiany swastyce”.</p>
                <p>&nbsp;</p>
                <h3><strong>Represje</strong></h3>
                <p>Rozwój związku przypadł na bardzo&nbsp; trudny czas rozwoju nazizmu w Niemczech. Od początku
                  działacze organizacji byli prześladowani, chociaż początkowo Hitler tolerował jej działanie w III
                  Rzeszy, obawiając się, że w razie ostrzejszych represji władze w Polsce ograniczą również prawa
                  mniejszości niemieckiej na terenie Polski. Wielu polskim działaczom, którzy otrzymali powołanie do
                  Wehrmachtu, <a href="https://pl.wikipedia.org/wiki/Gestapo">Gestapo</a> wbijało pieczątkę „Achtung
                  Pole!” – <em>Uwaga Polak!</em>. We wrześniu 1937 roku Gestapo zakazało członkom <a
                    href="https://pl.wikipedia.org/wiki/Zwi%C4%85zek_Harcerstwa_Polskiego_w_Niemczech">Związku
                    Harcerstwa Polskiego w Niemczech</a> noszenia mundurów harcerskich i lilijki z Rodłem.&nbsp;</p>
                <p>W okresie międzywojennym nagminne były ataki oraz szykany wobec członków mniejszości polskiej na
                  terenie III Rzeszy. Opolskie Gestapo nakazało proboszczowi Melcowi ze Starego Koźla opuszczenie terenu
                  niemieckiego Górnego Śląska do dnia 12 stycznia 1938 roku. Zakaz dotyczył również osiedlenia się na
                  terenie przygranicznym. W sprawie wydalenia księdza ZPwN interweniował u prezydenta rejencji w Opolu
                  oraz w&nbsp; Ministerstwie Spraw Wewnętrznych Rzeszy i Prus.&nbsp;</p>
                <p>Wszyscy członkowie Związku Polaków w Niemczech wpisywani byli na specjalną listę „wrogów Rzeszy”,
                  tzw. <a href="https://pl.wikipedia.org/wiki/Sonderfahndungsbuch_Polen">Sonderfahndungsbuch Polen</a>,
                  przygotowaną przez kontrwywiad służby bezpieczeństwa <a
                    href="https://pl.wikipedia.org/wiki/Schutzstaffel">SS</a>, tzw. <a
                    href="https://pl.wikipedia.org/wiki/Sicherheitsdienst">Sicherheitsdienst</a> w Berlinie. Na jej
                  podstawie&nbsp; po wybuchu II Wojny Światowej przeprowadzano masowe aresztowania polskiej inteligencji
                  oraz dokonywano czystek etnicznych ramach w <a
                    href="https://pl.wikipedia.org/wiki/Operacja_Tannenberg">operacji Tannenberg</a> i <a
                    href="https://pl.wikipedia.org/wiki/Intelligenzaktion">Intelligenzaktion</a>. Władze niemieckie
                  przygotowały jeszcze przed wojną policyjne tzw. „akcje specjalne”, wymierzone w mniejszość polską
                  zamieszkałą na terenie Niemiec. W piątek 25 sierpnia 1939 roku niemiecka policja aresztowała
                  wszystkich uczniów polskiego gimnazjum w <a
                    href="https://pl.wikipedia.org/wiki/Kwidzyn">Kwidzynie</a> w wieku od lat 9 do 17, których wraz z
                  nauczycielami skierowano do tzw. „obozu ochronnego”. Z chwilą wybuchu <a
                    href="https://pl.wikipedia.org/wiki/II_wojna_%C5%9Bwiatowa">II wojny światowej</a> Związek został
                  zdelegalizowany przez władze <a href="https://pl.wikipedia.org/wiki/III_Rzesza">III Rzeszy</a>, jego
                  majątek skonfiskowany, część działaczy rozstrzelano, a ok. 1200 członków uwięziono w <a
                    href="https://pl.wikipedia.org/wiki/Ob%C3%B3z_koncentracyjny">obozach koncentracyjnych</a>, głównie
                  w <a href="https://pl.wikipedia.org/wiki/Hohenbruch_(KL)">Hohenbruch</a>, <a
                    href="https://pl.wikipedia.org/wiki/Mauthausen-Gusen_(KL)">Mauthausen-Gusen</a> i <a
                    href="https://pl.wikipedia.org/wiki/Sachsenhausen_(KL)">Sachsenhausen</a>.&nbsp;</p>
                <p>Źródło: https://pl.wikipedia.org/wiki/Zwi%C4%85zek_Polak%C3%B3w_w_Niemczech</p>
                <p><strong>Władze Związku&nbsp;</strong></p>
                <p>&nbsp;</p>
                <ul>
                  <li>3 grudnia 1922 – 9 lutego 1933: <a
                    href="https://pl.wikipedia.org/wiki/Stanis%C5%82aw_Sierakowski_(pose%C5%82)">Stanisław
                    Sierakowski</a></li>
                  <li>9 lutego 1933 – 21 kwietnia 1939: <a
                    href="https://pl.wikipedia.org/wiki/Boles%C5%82aw_Doma%C5%84ski">ks. Bolesław Domański</a></li>
                  <li>21 kwietnia 1939 – wrzesień 1939: p.o. <a href="https://pl.wikipedia.org/wiki/Stefan_Szczepaniak">Stefan
                    Szczepaniak</a></li>
                  <li>3 czerwca 1951 – 6 marca 1964: Stefan Szczepaniak</li>
                  <li>22 listopada 1964 – 23 sierpnia 1969: ks. Józef Franciszek Styp-Rekowski</li>
                  <li>15 marca 1970 – 1988: ks. Edmund Forycki</li>
                  <li>20 marca 1988 – 18 marca 1991: Teodor Wesołowski</li>
                  <li>1991–1993: S. Jabłoński</li>
                  <li>1993–1997: Tadeusz Hyb</li>
                  <li>1997 – 22.02.2004: Józef Młynarczyk</li>
                  <li>22.02.2004 – maj 2009: Zdzisław Duda</li>
                  <li>czerwiec 2009 – 06.03.2010: p.o. <a
                    href="https://pl.wikipedia.org/wiki/Marek_W%C3%B3jcicki_(ekonomista)">Marek Wójcicki</a></li>
                  <li>06.03.2010 – 01.05.2013: Marek Wójcicki</li>
                  <li>od 01.05.2013: Josef Hilarius Malinowski</li>
                </ul>
                <p><br/><br/></p>
                <p><strong>Członkowie związku związani ze Szczecinem&nbsp;</strong></p>
                <p>&nbsp;</p>
                <p>W Szczecinie zachowało się niewiele śladów po Polakach mieszkających tu przed 1945 r. Przede
                  wszystkim przypominają o nich nieliczne tablice pamiątkowe. Jedna z nich znajduje się przy placu
                  Lotników 1, która wspomina nieistniejącą już dziś polską szkołę, która działała w tym miejscu w latach
                  1931 – 34. Niedaleko znajduje się plac Rodła przy którym, znajdziemy obelisk upamiętniający Związek
                  Polaków w Niemczech. Także na jednej ze ścian szczecińskiej katedry znajdziemy tablicę z Rodłem i
                  przykazaniami dla Polonii. <br/>Nieco dalej od centrum, przy ulicy Monte Casino 30, znajduje się
                  tablica, upamiętniająca istniejący w tym miejscu konsulat polski. Warto tu wspomnieć, że przy
                  konsulacie działała także polska placówka wywiadowcza. Szlak śladem&nbsp; Rodła znajdziecie państwo w
                  zakładce naszej strony -Gra miejska.&nbsp;&nbsp;&nbsp;</p>
                <p>Szczególnie aktywnym członkiem Związku Polaków&nbsp; w Niemczech ze Szczecina była <strong>Maksymilian
                  Golisz</strong>.&nbsp; Urodzony <a href="http://encyklopedia.szczecin.pl/wiki/18_pa%C5%BAdziernika">18
                  października</a><a href="http://encyklopedia.szczecin.pl/wiki/1906">1906</a> r. w Wielu, w rodzinie
                  kupieckiej. W <a href="http://encyklopedia.szczecin.pl/wiki/1914">1914</a> r. wraz z matką po śmierci
                  ojca, przeniósł się do Sztumu, gdzie uczęszczał do szkoły. Przy wsparciu materialnym hrabiny Heleny
                  Sierakowskiej uczęszczał do Państwowego Seminarium Nauczycielskiego w Lubawie, które ukończył w <a
                    href="http://encyklopedia.szczecin.pl/wiki/1926">1926</a> r.&nbsp;</p>
                <p>Po zakończeniu praktyki nauczycielskiej w Łaszewie i Dalkach koło Gniezdna powrócił na Powiśle, gdzie
                  został sekretarzem Okręgu Zarządu Związku Polaków w Niemczech w Sztumie, a następnie sekretarzem
                  Polsko-Katolickiego Towarzystwa Szkolnego na Powiśle. W kolejnych latach wspólnie z Janem Boenigkiem
                  zakładał polskie szkoły w Starym Targu, Trzcianie, Waplewie, Postolinie, Dąbrówce Pruskiej,
                  Mikołajkach Pomorskich oraz Nowym Targu. W <a
                    href="http://encyklopedia.szczecin.pl/wiki/1930">1930</a> r. zainicjował powstanie powiślańskiego
                  oddziału Towarzystwa Nauczycieli Polskich, którego został skarbnikiem. W lipcu <a
                    href="http://encyklopedia.szczecin.pl/wiki/1932">1932</a> r. władze niemieckie odebrały mu prawo
                  wykonywania zawodu nauczycielskiego, co było spowodowane nagłośnieniem przez niego w prasie polonijnej
                  napadu bojówki hitlerowskiej na szkołę polską w Mikołajkach. Jednocześnie nakazano mu natychmiastowe
                  opuszczenie Powiśla. W tym samym czasie wszedł w ostry konflikt z Kazimierzem Donimirskim,
                  współwłaścicielem banków w Kwidzynie i Sztumie, jednym z liderów mniejszości polskiej na tym
                  terenie.&nbsp;</p>
                <p>Po krótkim pobycie w Berlinie skierowany do <a
                  href="http://encyklopedia.szczecin.pl/wiki/Szczecin">Szczecina</a>, do którego przybył <a
                  href="http://encyklopedia.szczecin.pl/wiki/1_maja">1 maja</a><a
                  href="http://encyklopedia.szczecin.pl/wiki/1933">1933</a> r., stając się głównym organizatorem
                  polskiej oświaty. Jako, że prawo do nauczania uzyskał on od władz niemieckich dopiero w kwietniu <a
                    href="http://encyklopedia.szczecin.pl/wiki/1934">1934</a> r., zajęcia w szkółce polskiej prowadził
                  nielegalnie, potajemnie nauczając języka polskiego, historii oraz geografii. Musiał być jednak bardzo
                  ostrożny, gdyż jego działalność była bacznie obserwowana przez szczecińską policję. Oprócz kierowania
                  polską szkołą, przejął nadzór nad powstałym w <a
                    href="http://encyklopedia.szczecin.pl/wiki/1927">1927</a> r. Polskim Towarzystwem Śpiewu „Chopin”,
                  przez pewien czas kierując również Domem Polskim w Szczecinie. <br/>Podejmował szereg inicjatyw
                  mających na celu ożywienie polskiego życia kulturalno - oświatowego w Szczecinie oraz w organizacjach
                  polonijnych, w tym celu m. in. bywał na wszystkich zebraniach organizacji polskich i uroczystościach
                  patriotycznych. Starał się również zaktywizować życie kulturalne wśród polskich robotników rolnych
                  zatrudnionych w powiatach. Wspólnie z polskim konsulem w Szczecinie – <a
                    href="http://encyklopedia.szczecin.pl/wiki/Heliodor_Sztark">Heliodorem Sztarkiem</a> dążył do
                  utworzenia w mieście filii banku PKO, przez którą robotnicy rolni mogliby przekazywać swoje
                  oszczędności do Polski. Planów tych nie udało się im jednak zrealizować. <br/>W lutym <a
                    href="http://encyklopedia.szczecin.pl/wiki/1934">1934</a> r. zorganizował pierwszą w Szczecinie
                  polską drużynę harcerską „Gryf”, z którą urządzał biwaki w Puszczy Bukowej oraz w podszczecińskich
                  miejscowościach zamieszkałych przez Polaków. Istotne znaczenie miały również organizowane przez niego
                  kolonie dla polskich dzieci w Polsce. W sierpniu 1934 r. wspólnie z Michałem Poznańskim reprezentował
                  szczecińską Polonię na II Światowym Kongresie Polaków z Zagranicy.&nbsp;</p>
                <p>Legitymacja członkowska Związku Polaków w Niemczech Maksymiliana Golisza, 1939, ze zbiorów Muzeum
                  Narodowego w Szczecinie.</p>
                <p>Po ich powrocie w związku z zagrożeniem aresztowaniem, Związek Polskich Towarzystw Szkolnych
                  zdecydował się przenieść Maksymiliana Golisza do Berlina na stanowisko kierownika Towarzystwa
                  Szkolnego „Oświata”, którego głównym celem było pielęgnowanie wśród Polonii berlińskiej polskiej
                  kultury, języka i tradycji. W tym samym czasie rozpoczął współpracę z polskim wywiadem wojskowym w
                  Berlinie i Szczecinie. Jego zadaniem było szukanie pośród obywateli niemieckich pochodzenia polskiego
                  przyszłych oficerów Wehrmachtu nadających się na współpracowników. W ten sposób do siatki wywiadowczej
                  udało mu się zwerbować m. in. Pawła Trzcińskiego – szeregowca 24. Pułku Piechoty w Iławie. Jako agent
                  w sierpniu <a href="http://encyklopedia.szczecin.pl/wiki/1939">1939</a> r. opracował raport o stanie
                  ludności polskiej na Warmii, co dawało pełny obraz warunków życia Polonii.&nbsp;</p>
                <p>Praca Golisza była przez cały czas dokładnie kontrolowana przez władze niemieckie. <a
                  href="http://encyklopedia.szczecin.pl/wiki/11_listopada">11 listopada</a><a
                  href="http://encyklopedia.szczecin.pl/wiki/1939">1939</a> r. został aresztowany i osadzony w obozie
                  koncentracyjnym w Oranienburgu, a potem w Sachsenhausen. Jako obywatel Niemiec, został zwolniony i
                  wcielony do niemieckiego wojska. Pomimo tego, śledztwo przeciwko niemu wciąż trwało.&nbsp;</p>
                <p>W <a href="http://encyklopedia.szczecin.pl/wiki/1941">1941</a> r. w warszawskim Forcie Legionów udało
                  się Niemcom odnaleźć znaczną część archiwum polskiego wywiadu, pośród którego znajdowały się dane
                  agentów, w tym nazwisko Maksymiliana Golisza. W efekcie został ponownie aresztowany i skazany na
                  śmierć. Wyrok wykonano <a href="http://encyklopedia.szczecin.pl/wiki/1_kwietnia">1 kwietnia</a><a
                    href="http://encyklopedia.szczecin.pl/wiki/1943">1943</a> r. w brandenburskim więzieniu. Podczas
                  egzekucji Golisz krzyknął: „Jeszcze Polska nie zginęła” i że śmierć jego będzie pomszczona. Spoczął na
                  cmentarzu w Brandenburgu.&nbsp;</p>
                <p>W <a href="http://encyklopedia.szczecin.pl/wiki/1972">1972</a> r., w pięćdziesiątą rocznicę
                  utworzenia Związku Polaków w Niemczech, urna z prochami Maksymiliana Golisza została sprowadzona do
                  Szczecina. Pochowano go w Alei Zasłużonych. Pośmiertnie odznaczony Krzyżem Grunwaldzkim Orderu
                  Odrodzenia Polski i mieczami do Krzyża Zasługi dla Związku Harcerstwa Polskiego. Na jego cześć została
                  nazwana jedna z <a
                    href="http://encyklopedia.szczecin.pl/wiki/Ulica_Maksymiliana_Golisza">ulic</a> Szczecina (Szczecin-<a
                    href="http://encyklopedia.szczecin.pl/wiki/%C5%BBelechowa">Żelechowa</a>), Szkoła Podstawowa nr 41 w
                  Szczecinie oraz Szkoła Podstawowa nr 2 w Sztumie.&nbsp;</p>
                <p>Źródło:&nbsp; http://encyklopedia.szczecin.pl/wiki/Maksymilian_Golisz</p>
                <p><strong>Aleksander Omieczyński</strong> (ur. <a href="https://pl.wikipedia.org/wiki/12_grudnia">12
                  grudnia</a><a href="https://pl.wikipedia.org/wiki/1909">1909</a> w <a
                  href="https://pl.wikipedia.org/wiki/Trzcianka">Trzciance</a>, zm. <a
                  href="https://pl.wikipedia.org/wiki/10_wrze%C5%9Bnia">10 września</a><a
                  href="https://pl.wikipedia.org/wiki/1941">1941</a> w <a
                  href="https://pl.wikipedia.org/wiki/Szczecin">Szczecinie</a>) – <a
                  href="https://pl.wikipedia.org/wiki/Nauczyciel">nauczyciel</a>, chórzysta, krzewiciel polskości. Był
                  synem małorolnego chłopa. Po ukończeniu Seminarium Nauczycielskiego w <a
                    href="https://pl.wikipedia.org/wiki/Lubawa">Lubawie</a> odbył praktykę w <a
                    href="https://pl.wikipedia.org/wiki/Grudzi%C4%85dz">Grudziądzu</a> i w 1933 r. wyjechał do <a
                    href="https://pl.wikipedia.org/wiki/Wroc%C5%82aw">Wrocławia</a>, gdzie miał zamiar podjąć pracę w
                  polskiej szkole. Władze niemieckie nie wyraziły jednak na to zgody, więc w 1935 r. wyjechał do
                  Szczecina. Po wielu trudnościach rozpoczął pracę w polskiej szkole, ucząc legalnie języka polskiego i
                  śpiewu, a nielegalnie <a href="https://pl.wikipedia.org/wiki/Historia_Polski">historii</a> i <a
                    href="https://pl.wikipedia.org/wiki/Geografia">geografii</a>. Prowadził również drużynę harcerską
                  "Gryf" i zuchową "Wiewiórki". Nie poprzestawał na pracy z dziećmi, działał także wśród młodzieży i
                  dorosłych, organizował <a href="https://pl.wikipedia.org/wiki/Ch%C3%B3r">chór</a>, <a
                    href="https://pl.wikipedia.org/wiki/Orkiestra">orkiestrę</a> i <a
                    href="https://pl.wikipedia.org/wiki/Teatr">teatr</a> amatorski.</p>
                <p>Źródło: <a
                  href="https://pl.wikipedia.org/wiki/Aleksander_Omieczy%C5%84ski">https://pl.wikipedia.org/wiki/Aleksander_Omieczy%C5%84ski</a>
                </p>
                <p><strong>Michał Kmiecik</strong> urodził się we wsi Krobia Wielkopolska dnia 31 sierpnia 1887r.
                  Pochodził z wielodzietnej rodziny chłopskiej. W wieku kilkunastu lat wyemigrował w poszukiwaniu pracy
                  z domu rodzinnego do Berlina. Tu ukończył dwuletnią nauka fachu krawieckiego. Cały swój wolny czas
                  poświęcał sprawom społecznym. Organizował i współtworzył grupy polskich towarzystw. Był jednym z
                  założycieli „Związku Polaków w Niemczech”. Celem tego związku było podtrzymywanie polskości,
                  poszanowanie tradycji min. poprzez znajomość i śpiewanie polskich pieśni. Michał Kmiecik okazywał
                  szczególną pomoc Polakom uczącym się fach krawieckiego.&nbsp;</p>
                <p>Jego pasja był śpiew i w 1919 roku związał się z chórem „Harmonia”. Władzom niemieckim nie podobała
                  się aktywność społeczna Kmiecika więc otrzymał nakaz wyjazdu z Niemiec. Wyjechał do Warszawy, a potem
                  do Poznania. Tu, poszukiwany przez hitlerowców w ukryciu przetrwał wojnę.</p>
                <p>W roku 1945 związał się z Ziemiami Zachodnimi. Przyjechał do Szczecina prowadząc pierwszy transport
                  osadników dnia 3 maja 1945r. Wkrótce sprowadził wielu osadników na Ziemie Odzyskane. Nadal, teraz już
                  w kraju prowadził działalność społeczną i kulturalną. Założył w Szczecinie pierwszy powojenny chór i
                  nazwał go także&nbsp; „Harmonia”.</p>
                <p>Za swoją pracę otrzymał odznakę Śpiewaka z Wieńcem&nbsp; Laurowym, Honorowa Odznakę Rodła, oraz
                  Odznakę Złotego Gryfa.&nbsp;</p>
                <p>Zmarł dnia 22 lutego 1966 roku, jest pochowany na szczecińskim Cmentarzu Centralnym.</p>
                <p>Źródło: <a href="https://sp61szczecin.edupage.org/text1/">https://sp61szczecin.edupage.org/text1/</a>
                </p>
                <p><strong>Józef Mozolewski</strong> (ur. <a href="https://pl.wikipedia.org/wiki/3_marca">3 marca</a><a
                  href="https://pl.wikipedia.org/wiki/1889">1889</a> w <a
                  href="https://pl.wikipedia.org/wiki/Rogo%C5%BAno">Rogoźnie</a>, zm. <a
                  href="https://pl.wikipedia.org/wiki/5_pa%C5%BAdziernika">5 października</a><a
                  href="https://pl.wikipedia.org/wiki/1974">1974</a> w <a
                  href="https://pl.wikipedia.org/wiki/Szczecin">Szczecinie</a>) – nauczyciel i działacz <a
                  href="https://pl.wikipedia.org/wiki/Zwi%C4%85zek_Polak%C3%B3w_w_Niemczech">Związku Polaków w
                  Niemczech</a>.&nbsp;</p>
                <p>Po ukończeniu seminarium nauczycielskiego w <a
                  href="https://pl.wikipedia.org/wiki/Go%C5%9Bcikowo">Paradyżu</a> koło <a
                  href="https://pl.wikipedia.org/wiki/%C5%9Awiebodzin">Świebodzina</a> w roku <a
                  href="https://pl.wikipedia.org/wiki/1910">1910</a> pracował w szkolnictwie powszechnym w <a
                  href="https://pl.wikipedia.org/wiki/Powiat_nowotomyski">powiecie nowotomyskim</a>. Po odzyskaniu
                  niepodległości przez Polskę prowadził zajęcia w seminarium nauczycielskim w <a
                    href="https://pl.wikipedia.org/wiki/Kcynia">Kcyni</a> (<a
                    href="https://pl.wikipedia.org/wiki/1921">1921</a>-<a
                    href="https://pl.wikipedia.org/wiki/1923">1923</a>) i w <a
                    href="https://pl.wikipedia.org/wiki/Bydgoszcz">Bydgoszczy</a>. Potem uzyskał dyplom nauczyciela
                  szkół średnich, po ukończeniu (jako <a
                    href="https://pl.wikipedia.org/wiki/Wolny_s%C5%82uchacz">ekstern</a>) studiów geograficznych na <a
                    href="https://pl.wikipedia.org/wiki/Uniwersytet_Lwowski">Uniwersytecie Jana Kazimierza</a> we <a
                    href="https://pl.wikipedia.org/wiki/Lw%C3%B3w">Lwowie</a> w <a
                    href="https://pl.wikipedia.org/wiki/1926">1926</a>.&nbsp;</p>
                <p>Od <a href="https://pl.wikipedia.org/wiki/1_stycznia">1 stycznia</a><a
                  href="https://pl.wikipedia.org/wiki/1930">1930</a> został nieoficjalnym inspektorem szkolnym Związku
                  Polskich Towarzystw Szkolnych w <a href="https://pl.wikipedia.org/wiki/Berlin">Berlinie</a>, formalnie
                  pozostawał przy tym na etacie nauczyciel w <a
                    href="https://pl.wikipedia.org/wiki/Z%C5%82ot%C3%B3w">Złotowie</a>.&nbsp;</p>
                <p>Aresztowany w Złotowie, po wybuchu <a href="https://pl.wikipedia.org/wiki/II_wojna_%C5%9Bwiatowa">II
                  wojny światowej</a> trafił - po krótkotrwałym pobycie w miejscowym więzieniu, potem w <a
                  href="https://pl.wikipedia.org/wiki/Lipka_(powiat_z%C5%82otowski)">Lipce</a> - od <a
                  href="https://pl.wikipedia.org/wiki/1_pa%C5%BAdziernika">1 października</a> 1939 do obozu w <a
                  href="https://pl.wikipedia.org/wiki/Sachsenhausen_(KL)">Sachsenhausen</a>. Przeżył tam aż do
                  wyzwolenia obozu przez Rosjan <a href="https://pl.wikipedia.org/wiki/3_maja">3 maja</a><a
                    href="https://pl.wikipedia.org/wiki/1945">1945</a>.&nbsp;</p>
                <p>Już <a href="https://pl.wikipedia.org/wiki/15_maja">15 maja</a> rozpoczął pracę w <a
                  href="https://pl.wikipedia.org/wiki/Koszalin">koszalińskim</a><a
                  href="https://pl.wikipedia.org/wiki/Kurator_o%C5%9Bwiaty">kuratorium</a> i trafił m.in. znów do
                  Złotowa, gdzie zorganizował Liceum Pedagogiczne. Po przeniesieniu kuratorium do <a
                    href="https://pl.wikipedia.org/wiki/Szczecin">Szczecina</a> w lipcu 1945 został naczelnikiem
                  Oddziału Kształcenia Nauczycieli. Od <a href="https://pl.wikipedia.org/wiki/1947">1947</a> do 1950
                  był <a href="https://pl.wikipedia.org/wiki/Starosta">starostą</a><a
                    href="https://pl.wikipedia.org/wiki/Powiat_szczeci%C5%84ski">powiatu szczecińskiego</a>. Później,
                  do <a href="https://pl.wikipedia.org/wiki/1957">1957</a>, wykładał w Studium Przygotowawczym do Szkół
                  Wyższych, potem (do <a href="https://pl.wikipedia.org/wiki/1965">1965</a>) w I Studium Nauczycielskim.
                  Na emeryturę przeszedł <a href="https://pl.wikipedia.org/wiki/1_wrze%C5%9Bnia">1 września</a><a
                    href="https://pl.wikipedia.org/wiki/1968">1968</a>.</p>
                <p>Źródło: <a
                  href="https://pl.wikipedia.org/wiki/J%C3%B3zef_Mozolewski_(nauczyciel)">https://pl.wikipedia.org/wiki/J%C3%B3zef_Mozolewski_(nauczyciel)</a>
                </p>
                <p><strong>Jerzy Kostencki</strong> (1906 – 1977) – dr praw, adwokat. Wiceprezes IV Dzielnicy ZPwN.</p>
                <p><strong>Klemens Gdaniec</strong> (1913 - 1971) -działacz Z P w N. Pionier Ziemi Szczecińskiej.</p>
                <p><strong>Stefan Sieja</strong> (1901 – 1975) – artysta plastyk, działacz Z P w N.</p>
                <p>&nbsp;<strong>Jan Klich</strong> (1903 – 1973) – działacz ZPwN.</p>
                <p><strong>Paweł Ledwolorz</strong> (25.01.1892 - 6.01.1978) –współzałożyciel ZPwN. Pionier</p>
                <p>Ziemi Szczecińskiej.&nbsp;</p>
                <p><strong>Józefa Gil</strong> (1872 – 1958)– nauczycielka, działaczka Z P w N.</p>
                <p><strong>Przydatne linki</strong></p>
                <p><strong>Strony internetowe:&nbsp;</strong></p>
                <p><strong>- Związek Polaków w Niemczech:&nbsp; </strong><a
                  href="http://www.zpwn.org/"><strong>http://www.zpwn.org/</strong></a></p>
                <p><strong>- artykuł Związek Polaków w Niemczech – "Polska jest naszą matką" : </strong><a
                  href="https://www.polskieradio.pl/39/156/Artykul/2359909,Zwiazek-Polakow-w-Niemczech-%E2%80%93-Polska-jest-nasza-matka"><strong>https://www.polskieradio.pl/39/156/Artykul/2359909,Zwiazek-Polakow-w-Niemczech-%E2%80%93-Polska-jest-nasza-matka</strong></a><strong> -</strong>
                </p>
                <p><strong>- artykuł „ Spod znaku Rodła” : </strong><a
                  href="https://ipn.gov.pl/pl/aktualnosci/48118,Spod-znaku-Rodla.html"><strong>https://ipn.gov.pl/pl/aktualnosci/48118,Spod-znaku-Rodla.html</strong></a><strong>&nbsp;</strong>
                </p>
                <p><strong>- </strong><a
                  href="https://encyklopedia.pwn.pl/haslo/Zwiazek-Polakow-w-Niemczech;4002391.html"><strong>https://encyklopedia.pwn.pl/haslo/Zwiazek-Polakow-w-Niemczech;4002391.html</strong></a>
                </p>
                <p><strong>- </strong><a
                  href="https://pl-pl.facebook.com/pages/category/Community-Organization/Zwi%C4%85zek-Polak%C3%B3w-w-Niemczech-Rod%C5%82o-906185812830014/"><strong>https://pl-pl.facebook.com/pages/category/Community-Organization/Zwi%C4%85zek-Polak%C3%B3w-w-Niemczech-Rod%C5%82o-906185812830014/</strong></a><strong>&nbsp;</strong>
                </p>
                <p><strong>- </strong><a
                  href="https://www.gov.pl/web/niemcy/polacy-i-polonia-w-niemczech"><strong>https://www.gov.pl/web/niemcy/polacy-i-polonia-w-niemczech</strong></a><strong>&nbsp;</strong>
                </p>
                <p><strong>- </strong><a
                  href="https://naszahistoria.pl/piata-prawda-brzmiala-polska-matka-nasza/ar/9463135"><strong>https://naszahistoria.pl/piata-prawda-brzmiala-polska-matka-nasza/ar/9463135</strong></a>
                </p>
                <p><strong>- </strong><a
                  href="https://szczecin.ipn.gov.pl/pl9/aktualnosci/153334,Upamietnienie-dzialaczy-Zwiazku-Polakow-w-Niemczech.html"><strong>https://szczecin.ipn.gov.pl/pl9/aktualnosci/153334,Upamietnienie-dzialaczy-Zwiazku-Polakow-w-Niemczech.html</strong></a><strong>&nbsp;</strong>
                </p>
                <p><strong>- </strong><a
                  href="https://radioszczecin.pl/1,430719,wystawa-zwiazku-polakow-w-niemczech-przed-siedzi"><strong>https://radioszczecin.pl/1,430719,wystawa-zwiazku-polakow-w-niemczech-przed-siedzi</strong></a><strong>&nbsp;</strong>
                </p>
                <p><strong>- </strong><a
                  href="http://sedina.pl/wordpress/index.php/2006/08/07/z-dziejw-szczeciskiej-polonii-4/"><strong>http://sedina.pl/wordpress/index.php/2006/08/07/z-dziejw-szczeciskiej-polonii-4/</strong></a><strong>&nbsp;</strong>
                </p>
                <p><strong>- </strong><a
                  href="https://www.traseo.pl/trasa/szczecin-znak-rodla"><strong>https://www.traseo.pl/trasa/szczecin-znak-rodla</strong></a><strong>&nbsp;</strong>
                </p>
                <p>&nbsp;</p>
                <p><strong>Materiały Video:</strong></p>
                <p>
                  <p>
                    <strong>Polska jest naszą matką:</strong>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/CwyNOPNx7zo"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                  </p>
                </p>
                <p><strong>Pięć prawd Polaków w Niemczech. Przemawia Bolesław Domański (Berlin 1938): </strong>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/OGPvk3E3xq0"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                </p>
                <p><strong>Przemowa prezesa Związku Polaków w Niemczech Bolesława Domańskiego (Berlin 1938): </strong>
                  <p><iframe width="560" height="315" src="https://www.youtube.com/embed/ul1nHfQ6jqQ"
                             title="YouTube video player" frameBorder="0"
                             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                             allowFullScreen></iframe></p>
                </p>
                <p><strong>Kongres Związku Polaków w Niemczech (Berlin 1938). Przemawia Jan Kaczmarek:</strong>
                <p>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/qImL134J5Oo"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                </p>
                </p>
                <p><strong>Kongres Związku Polaków w Niemczech (Berlin 1938). "Pieśń Rodła": </strong>
                  <p>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/PZLPjV6XqFI"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                  </p>
                </p>
                <p><strong>Kongres Związku Polaków w Niemczech (Berlin 1938). Pieśń "Hasło Polaków w
                  Niemczech": </strong>
                  <p>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/COoefoZqkk8"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
)
  ;
}

export default Products;
