import React from 'react';

function Media() {
  return (
    <>
      <div className="container imgGallery siteWrap" data-aos="fade-up"
           data-aos-anchor-placement="top-center">
        <h2>Video</h2>
        <div>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/9Z3RRmlj5xg" title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
        </div>
      </div>
    </>
  );
}

export default Media;
