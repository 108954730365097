import React, {useEffect} from 'react';
import '../styles/wrapper.scss'
import '../styles/home.scss'
import Aos from "aos";
import About from '../components/About'
import AboutProduct from './../components/AboutProduct'
import Media from './../pages/Media'
import Header from "./../components/Header"

function Home() {

  useEffect(() => {
    Aos.init(
      {
        offset: 120,
        mirror: true,
        delay: 150,
      }
    );
  }, [])

  return (
    <>
      <Header/>
      <About/>
      <AboutProduct/>
      <Media/>
    </>
  );
}

export default Home;
