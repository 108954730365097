import React from "react";
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom'
import {SidebarData} from "./components/SidebarData";
import SiteLogo from "./img/logo.png"
import {Image} from 'antd';

//GA
import ReactGA from "react-ga4";

// Pages
import Home from './pages/Home'
import Media from './pages/Media'
import Products from './pages/Products'
import Gallery from './img/album/Gallery'
import Footer from "./components/Footer"
import Navbar from "./components/Navbar"

import * as BiIcons from 'react-icons/bi'

// Antd
import {BackTop} from 'antd'

// ProgresBar
import ProgressBar from "react-scroll-progress-bar"; //Add this line

// Styles
import './styles/home.scss'
import './styles/bootstrap-grid.css'

// GTM
import TagManager from 'react-gtm-module'

ReactGA.initialize([
  {
    trackingId: "G-0D11ZCQQ0F",
  },
]);

const tagManagerArgs = {
  gtmId: 'GTM-MBMTS52'
}

TagManager.initialize(tagManagerArgs)

function App() {
  const styleBacktop = {
    height: 40,
    width: 40,
    lineHeight: '40px',
    borderRadius: 4,
    backgroundColor: 'yar#1088e9',
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
    position: 'fixed',
    bottom: '0',
    right: '0',
    margin: '20px',
  };

  return (
    <>
      <div className="App">
        <BackTop>
          <div style={styleBacktop}><BiIcons.BiArrowToTop/></div>
        </BackTop>
        <ProgressBar/>
        <Router>
          <div className="container topMenu">
            <Navbar/>
            <span className="siteTitle">
                          <Image
                            width={50}
                            src={SiteLogo}
                          />
            </span>
            <div className="defaultMenu">
              {SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                )
              })}
            </div>
          </div>
          <Switch>
            <div className="wrapper">
              <Route path='/' exact component={Home}/>
              <Route path='/media' exact component={Media}/>
              <Route path='/blog' exact component={Products}/>
              <Route path='/galeria' exact component={Gallery}/>
            </div>
          </Switch>
          <Footer/>
        </Router>
      </div>
    </>
  );
}

export default App;