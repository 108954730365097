import React from 'react';
import {RightOutlined} from "@ant-design/icons";
import headerIMG from "./../img/banner.jpg"

function Header() {

  return (
    <div className="container-fluid header">
      <div className="row hero justify-content-center" data-aos="fade-up"
           data-aos-anchor-placement="top-center">
        <div className="col-lg-6">
          <img className="img-fluid" src="./../img/headerIMG.png" alt=""/>
          <img src={headerIMG} alt="" className="img-fluid"/>
        </div>
        <div className="col-lg-6 heroTitle">
          <h1>Szlakiem Rodła</h1>
          <p>Walka o zachowanie polskości na ziemiach zachodnich</p>
          <span><a className="clickButton" href="#wrapper">przejdź dalej</a> <RightOutlined/></span>
        </div>
      </div>
    </div>
  );
}

export default Header;