import React from "react";
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as HiIcons from 'react-icons/hi';

export const SidebarData = [
  {
    title: 'Główna',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text'
  },
  {
    title: 'Blog',
    path: '/blog',
    icon: <AiIcons.AiOutlineRead />,
    cName: 'nav-text'
  },
  {
    title: 'Filmy',
    path: '/media',
    icon: <HiIcons.HiFilm />,
    cName: 'nav-text'
  },
  {
    title: 'Album',
    path: '/galeria',
    icon: <FaIcons.FaPhotoVideo />,
    cName: 'nav-text'
  },
]