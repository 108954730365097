import React from 'react';
import {SRLWrapper} from "simple-react-lightbox";
// Galeria
import img1 from "./1.JPG"
import img2 from "./2.JPG"
import img4 from "./4.JPG"
import img5 from "./5.JPG"
import img6 from "./6.JPG"
import img7 from "./7.JPG"
import img8 from "./8.JPG"
import img9 from "./9.JPG"
import img10 from "./10.JPG"
import img11 from "./11.JPG"
import img12 from "./12.JPG"
import img13 from "./13.JPG"
import img14 from "./14.JPG"
import img15 from "./15.JPG"
import img16 from "./16.JPG"

function Gallery() {
  return (
    <SRLWrapper>
      <div className="container-fluid imgGallery siteWrap" data-aos="fade-up">
        <div>
          <img className="img-fluid" src={img2} alt=""/>
          <img className="img-fluid" src={img16} alt=""/>
          <img className="img-fluid" src={img4} alt=""/>
          <img className="img-fluid" src={img5} alt=""/>
          <img className="img-fluid" src={img6} alt=""/>
          <img className="img-fluid" src={img7} alt=""/>
          <img className="img-fluid" src={img8} alt=""/>
          <img className="img-fluid" src={img9} alt=""/>
          <img className="img-fluid" src={img10} alt=""/>
          <img className="img-fluid" src={img12} alt=""/>
          <img className="img-fluid" src={img13} alt=""/>
          <img className="img-fluid" src={img14} alt=""/>
          <img className="img-fluid" src={img15} alt=""/>
          <img className="img-fluid" src={img1} alt=""/>
          <img className="img-fluid" src={img11} alt=""/>
        </div>
      </div>
    </SRLWrapper>
  );
}

export default Gallery;
