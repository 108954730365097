import React from 'react';
import * as FaIcons from 'react-icons/fa';
import {PeopleData} from "./PeopleData";
import Gallery from '../img/album/Gallery'

function About() {

  <script>
    AOS.init(
    delay: 550,
    );
  </script>

  return (
    <>
      <div className="container my-5 siteWrap" id="wrapper">
        <h2 data-aos="fade-in"><FaIcons.FaChrome/> 12 grudnia 2021r.</h2>
        <div className="row">
          <div className="col-lg-12" data-aos="fade-up">
            <h2 className="my-3">Fundacja Edukacji Strzeleckiej i Historycznej „ Semper Partati”</h2>
            <h4 className="my-3">Razem z grupą wolontariuszy w ramach projektu <strong>„Szlakiem Rodła walka o zachowanie Polskości na ziemiach zachodnich”</strong></h4>

            <p>Uczciła pamięć szczecinian <strong>spod znak Rodła.</strong> Podczas akcji posprzątano groby i zapalano znicze w kolorze
              białoczerwonym. Groby znanych działaczy Związku Polaków w Niemczech, którzy walczyli o zachowanie
              polskości a także wielu z nich było później pionierami Szczecina znajdują się w alei zasłużonych na
              Cmentarzu Centralnym w Szczecinie. </p>
          </div>
        </div>
        <Gallery/>
      </div>
      <div className="container walkingBox">
        <div className="row">
          <div className="col-lg-12" data-aos="fade-up">
            <div className="listedTitle">
              <h3>W alei zasłużonych na
                Cmentarzu Centralnym w Szczecinie</h3>
              <h3>Spoczywają tutaj:</h3>
            </div>
            <div className="listed">
              <ul>
                {PeopleData.map((item, index) => {
                  return (
                    <li key={index} className="listedPerson">
                      <div>
                        <h2>{item.title}</h2>
                        <p>{item.date}</p>
                      </div>
                      <span>{item.position}</span>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
