import React from 'react';
import {BankOutlined, MessageOutlined} from "@ant-design/icons";

function Footer() {

  const iconStyle = {
    fontSize: '50px'
  }

  return (
    <>
      <div id="footer" className="container footer" data-aos="fade-in"
           data-aos-anchor-placement="top">
        <div className="row"></div>
        <div className="row col-lg-12">
          <div className="col-lg-3">
            <MessageOutlined
              style={iconStyle}
            />
            <h3>Email Us</h3>
            <p><a href="mailto:kontakt@1sks.pl">kontakt@1sks.pl</a></p>
          </div>
          <div className="col-lg-3">
            <BankOutlined
              style={iconStyle}
            />
            <h3>O nas</h3>
            <p>Fundacja Edukacji Strzeleckiej i Historycznej „Semper Parati”
            </p>
          </div>
          <div className="col-lg-3">
            <BankOutlined
              style={iconStyle}
            />
            <h3>Adress</h3>
            <p>ul. Adres Strzelców Karpackich 15
            </p>
          </div>
          <div className="col-lg-3">
            <BankOutlined
              style={iconStyle}
            />
            <h3>Website</h3>
            <p><a href="https://stary-bunkier.pl/">https://stary-bunkier.pl/</a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;