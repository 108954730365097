import React from 'react';
import * as FaIcons from 'react-icons/fa';
import img360 from './../img/miniatura-spacer.png'
import { Button, Image } from 'antd';

function AboutProduct() {

  return (
    <>
      <div className="container my-5 siteWrap">
        <div className="row">
          <div className="col-lg-6" data-aos="fade-right">
            <h2 data-aos="fade-in"><FaIcons.FaCodiepie/>Obejrzyj cały spacer 360</h2>
            <p>Zapraszamy na spacer wirtualny po najważniejszych miejscach związanych z "Rodłem" i walką o  Polskość w Szczecinie.</p>
            <Button type="primary"><a href="https://spacer.znakrodla.pl/">Otwórz stronę</a></Button>
          </div>
          <div className="col-lg-6">
            <Image
              width={600}
              src={img360}
            />
          </div>
        </div>
      </div>
    </>

  );
}

export default AboutProduct;