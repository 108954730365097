export const PeopleData = [
  {
    title: 'SIEJA STEFAN',
    date: 'ur. 1891-08-28, zm. 1972-05-22',
    position: 'kwatera 44, rząd 1, numer grobu 15',
  },
  {
    title: 'JÓZEFA GIL',
    date: 'ur. 1872-05-21, zm. 1958-05-20',
    position: 'kwatera 25C, rząd 7, numer grobu 6A',
  },
  {
    title: 'KLICH JAN ROMAN ',
    date: 'ur. 1903-01-02, zm. 1973-11-17',
    position: 'kwatera 44, rząd 1, numer grobu 16',
  },
  {
    title: 'LEDWOLORZ PAWEŁ',
    date: 'ur. 1872-05-21, zm. 1958-05-20',
    position: 'kwatera 44, rząd 1, numer grobu 22',
  },
  {
    title: 'GDANIEC KLEMENS',
    date: 'ur. 1913-01-06, zm. 1971-12-07',
    position: 'kwatera 44, rząd 1, numer grobu 10',
  },
  {
    title: 'KOSTENCKI JERZY',
    date: 'ur. 1906-02-06, zm. 1977-11-17',
    position: 'kwatera 60A, rząd 2, numer grobu 4',
  },
  {
    title: 'GOLISZ MAKSYMILIAN',
    date: 'ur. 1906-10-18, zm. 1943-04-01',
    position: 'kwatera 44, rząd 1, numer grobu 14',
  },
  {
    title: 'KMIECIK MICHAŁ',
    date: 'ur. 1887-08-31, zm. 1966-02-21',
    position: 'kwatera 21, rząd 2, numer grobu 16',
  },
  {
    title: 'MOZOLEWSKI JÓZEF',
    date: 'ur. 1889-03-03, zm. 1974-10-08',
    position: 'kwatera 44, rząd 1, numer grobu 18',
  },
  {
    title: 'OMIECZYŃSKI ALEKSANDER',
    date: 'ur. 1909-12-12, zm. 1941-09-10',
    position: 'kwatera 44, rząd 1, numer grobu 13',
  },
]