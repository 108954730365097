import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import SimpleReactLightbox from 'simple-react-lightbox'
import 'aos/dist/aos.css'; // You can also use <link> for styles
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <App />
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById('root')
);